import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';

Cookies.set('test', 'dsfkjskfjklfjklsdfjksfj', {
  expires: 0.5,
});

function App() {
  const [stat, setStat] = useState({
    success: 0,
    fail: 0,
    lastUpdateTime: new Date(),
  });
  useEffect(() => {
    const timer = setInterval(() => {
      const value = Cookies.get('test');
      if (value) {
        setStat((s) => ({
          ...s,
          success: s.success + 1,
          lastUpdateTime: new Date(),
        }));
      } else {
        setStat((s) => ({
          ...s,
          fail: s.fail + 1,
          lastUpdateTime: new Date(),
        }));
      }
      Cookies.set('test', 'dsfkjskfjklfjklsdfjksfj', {
        expires: 0.5,
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });

  return (
    <div className="App">
      <pre>{JSON.stringify(stat, null, 4)}</pre>
    </div>
  );
}

export default App;
